<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">BANK</h3>
          <div class="form-col-select ml-2">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Find Bank"
            />
          </div>
          <b-button
            variant="primary"
            class="px-3 mobile-w100"
            @click="addBank"
            v-if="ability.can('create', 'bank')"
            :to="{ name: 'banks-create' }"
          >
            <span class="text-nowrap"
              ><feather-icon size="14" icon="PlusIcon" /> Bank
            </span>
          </b-button>
        </div>
      </div>
      <b-row style="margin: 0 10px">
        <b-col cols="12" md="3" lg="2" class="pr-md-0">
          <div
            class="userFilterTable form-col-select"
            :class="{ open: uftbOpen }"
            @click="uftbOpen = !uftbOpen"
          >
            <b-table
              sticky-header
              ref="refUserListTable"
              class="position-relative"
              :items="userOptions"
              responsive
              :fields="userColumns"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              select-mode="single"
              selectable
              @row-selected="onUserRowSelected"
              id="userFilterTable"
            >
            </b-table>
            <feather-icon size="18" icon="ChevronDownIcon" />
            <hr class="p-0 m-0 pb-1" />
          </div>
        </b-col>
        <b-col cols="12" md="9" lg="10" class="pl-md-0 mt-1 mt-md-0">
          <b-table
            sticky-header
            ref="refBankListTable"
            class="position-relative"
            :items="fetchBanksByRm"
            responsive
            :fields="tableBankRMColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            style="max-height: 80vh"
          >
            <!-- Column: Bank Name -->
            <template #cell(name)="data">
              <b-link
                class="text-decoration-underline"
                v-if="ability.can('update', 'bank')"
                :to="{ name: 'banks-edit', params: { id: data.item.id } }"
                >{{ data.item.name }}</b-link
              >
              <div v-else>{{ data.item.name }}</div>
            </template>
            <template #cell(authorised_signers)="data">
              <div
                v-for="(user, index) in data.item.contents.filter(
                  (item) => item.authorization_type_id == 1 && item.user_id == userFilter
                )"
                :key="`authorised_signer_${index}`"
              >
                <span
                  v-if="user && user.user"
                  :class="{
                    'text-danger': user.date_of_removed != null,
                    'text-success': user.user_id == userFilter,
                  }"
                  >{{ user.user.name }}</span
                >
              </div>
            </template>
            <template #cell(authorized_representitives)="data">
              <div
                v-for="(user, index) in data.item.contents.filter(
                  (item) => item.authorization_type_id == 2 && item.user_id == userFilter
                )"
                :key="`authorized_representitives_${index}`"
              >
                <span
                  v-if="user && user.user"
                  :class="{
                    'text-danger': user.date_of_removed != null,
                    'text-success': user.user_id == userFilter,
                  }"
                  >{{ user.user.name }}</span
                >
              </div>
            </template>
            <template #cell(authorized_admin)="data">
              <div
                v-for="(user, index) in data.item.contents.filter(
                  (item) => item.authorization_type_id == 3 && item.user_id == userFilter
                )"
                :key="`authorized_admin_${index}`"
              >
                <span
                  v-if="user && user.user"
                  :class="{
                    'text-danger': user.date_of_removed != null,
                    'text-success': user.user_id == userFilter,
                  }"
                  >{{ user.user.name }}</span
                >
              </div>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-link
                v-if="ability.can('read', 'bank')"
                :to="{ name: 'banks-detail', params: { id: data.item.id } }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-link>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBanks"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import bankStoreModule from "../bankStoreModule";
import useBanksListByRm from "./useBanksListByRm";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    onUserRowSelected(item) {
      console.log(item);
      if (item.length > 0) {
        this.userFilter = item[0].id;
      } else {
        this.userFilter = null;
      }
    },
    confirmDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the bank.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-bank/deleteBank", { bank_id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    addBank() {
      this.isAddNewBankSidebarActive = true;
    },
  },
  setup() {
    const APP_CLIENT_STORE_MODULE_NAME = "app-bank";
    const isAddNewBankSidebarActive = ref(false);

    if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
      store.registerModule(APP_CLIENT_STORE_MODULE_NAME, bankStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
        store.unregisterModule(APP_CLIENT_STORE_MODULE_NAME);
    });

    onMounted(() => {
      fetchOptions();
    });

    const userOptions = ref([]);

    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptionList", { user_bank: true })
        .then((response) => {
          userOptions.value = response.data.user_banks;
        })
        .catch((error) => {
          console.log("fail to fetch options.");
        });
    };
    const uftbOpen = ref(false);

    const {
      fetchBanksByRm,
      tableColumns,
      perPage,
      currentPage,
      totalBanks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refBankListTable,
      ability,
      userColumns,
      tableBankRMColumns,
      userFilter,
    } = useBanksListByRm();

    return {
      isAddNewBankSidebarActive,
      fetchBanksByRm,
      tableColumns,
      perPage,
      currentPage,
      totalBanks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refBankListTable,
      ability,
      userOptions,
      userColumns,
      tableBankRMColumns,
      userFilter,
      uftbOpen,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/variables";

.table.b-table > tbody .b-table-row-selected.table-active td {
  background-color: $primary-color-4;
  color: white;
  border-radius: 5px;
  margin-inline: 3px;
}
.b-table-sticky-header {
  max-height: 60vh;
}
.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background: #fff;
}

.userFilterTable {
  .table.b-table > tbody .b-table-row-selected.table-active td {
    margin-inline: 0px;
  }
  .table {
    thead {
      tr {
        &:before {
          content: none;
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          &:before {
            // content:none;
          }
        }
      }
    }
    tr,
    td {
      width: 100%;
      display: block;
    }
  }
  hr,
  svg {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .b-table-sticky-header {
    max-height: 30vh;
    overflow: auto;
  }
  .userFilterTable {
    box-shadow: 0px 0px 5px rgba(148, 200, 187, 0.6);
    &.open {
      .table {
        tbody {
          max-height: 100vh;
        }
      }
      hr {
        display: block;
      }
    }
    .table {
      tbody {
        overflow: hidden;
        max-height: 0;
        display: block;
        transition: 500ms ease;
      }
    }
    svg {
      display: block;
    }
  }
}
@media screen and (max-width: 576px) {
}
</style>
