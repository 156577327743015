import { render, staticRenderFns } from "./BanksDetailList.vue?vue&type=template&id=061af118&scoped=true&"
import script from "./BanksDetailList.vue?vue&type=script&lang=js&"
export * from "./BanksDetailList.vue?vue&type=script&lang=js&"
import style0 from "./BanksDetailList.vue?vue&type=style&index=0&id=061af118&lang=scss&scoped=true&"
import style1 from "./BanksDetailList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "061af118",
  null
  
)

export default component.exports